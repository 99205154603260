import {
	useDeleteNotification,
	useGetNotifications,
	useUpdateNotification,
} from '@/api/notification';
import { INotification } from '@/interfaces/notification/INotification';
import { Card, MenuItem, Popover, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import NotificationItem from './notificationItem';
import { APP, FREIGHT, OFFERS, DELIVERIES } from '@/config/path';
import { notificationTypeEnum, notificationTitleTypeEnum } from '@/enums/NotificationEnum';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';

interface LanguagePopoverProps {
	anchorEl: null | Element;
	onClose?: () => void;
	open?: boolean;
	setUnreadMessagesCount: (unreaded: number) => void;
}

export const generateUrlPath = (
	pathType: notificationTypeEnum,
	relationId: string,
	notification: notificationTitleTypeEnum,
): string => {
	if (notification === notificationTitleTypeEnum.DELIVERY_FINISHED) {
		return `/${APP}/${DELIVERIES.INDEX}/view/${relationId}`;
	}
	if (notification === notificationTitleTypeEnum.COUNTEROFFER_ACCEPTED) {
		return `/${APP}/${DELIVERIES.INDEX}/view/${relationId}`;
	}
	if (pathType === notificationTypeEnum.COUNTER_OFFER) {
		return generatePath(`/${APP}/${OFFERS.INDEX}/negotiate/${relationId}`, {
			offerID: relationId,
		});
	}
	if (pathType === notificationTypeEnum.PRIVATE_FREIGHT_REQUEST) {
		return generatePath(`/${APP}/${FREIGHT.REQUEST}/${relationId}`);
	}
	if (pathType === notificationTypeEnum.OFFER) {
		if (notification === notificationTitleTypeEnum.OFFER_ACCEPTED) {
			return `/${APP}/${DELIVERIES.INDEX}/view/${relationId}`;
		}
		return generatePath(`/${APP}/${OFFERS.INDEX}/${OFFERS.VIEW}`, { offerID: relationId });
	}
	if (pathType === notificationTypeEnum.DELIVERY) {
		return generatePath(`/${APP}/${DELIVERIES.INDEX}/${DELIVERIES.VIEW}`, {
			deliveryID: relationId,
		});
	}
	if (pathType === notificationTypeEnum.INTEREST) {
		return `/app/freight/offers/${relationId}`;
	}
	return '';
};

const Notifications = ({
	item,
	changeStatusNotification,
	deleteNotification,
}: {
	item: INotification;
	changeStatusNotification: (notification: INotification) => void;
	deleteNotification: (notification: INotification) => void;
}) => (
	<MenuItem>
		<NotificationItem
			notification={item}
			changeStatusNotification={changeStatusNotification}
			deleteNotification={deleteNotification}
		/>
	</MenuItem>
);

export const NotificationPopOver: FC<LanguagePopoverProps> = (props) => {
	const { t } = useTranslation();
	const { anchorEl, onClose, open, setUnreadMessagesCount } = props;
	const [updateList, setUpdateList] = useState(true);
	const { data = [], isFetching } = useGetNotifications(updateList);
	const notificationList = data as INotification[];
	const navigate = useNavigate();

	useEffect(() => {
		if (!isFetching && notificationList && data) {
			const unreadNotifications = notificationList.filter((item: INotification) => item.active);
			setUnreadMessagesCount(unreadNotifications.length);
			setUpdateList(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching, notificationList]);

	const { mutate: removeNotification } = useDeleteNotification();
	const { mutate: updateNotification } = useUpdateNotification();

	const deleteNotification = (notification: INotification) => {
		removeNotification({ _id: notification._id });
		const notificationIndex = notificationList.indexOf(notification);
		notificationList.splice(notificationIndex, 1);
		setUnreadMessagesCount(notificationList.length);
	};

	const changeStatusNotification = (notification: INotification) => {
		updateNotification({ _id: notification._id });
		navigate(generateUrlPath(notification.type, notification.relationId, notification.notification));
		setUpdateList(true);
	};

	const listHasNotifications = () => {
		return notificationList.length >= 1;
	};

	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: -10,
				horizontal: 'right',
			}}
			keepMounted
			onClose={onClose}
			open={!!open}
			PaperProps={{ sx: { width: '450px' } }}
			transitionDuration={0}
		>
			<Card className='p-4'>
				<Typography className='font-bold' variant='subtitle2'>
					<div className='flex flex-row justify-between'>
						<span>{t('notificationPopover.heading')}</span>

						<Button
							variant='outlined'
							size='small'
							onClick={() => {
								onClose && onClose();
							}}
						>
							X
						</Button>
					</div>
				</Typography>
			</Card>
			<div>
				{listHasNotifications() ? (
					notificationList.map((item, index) => (
						<Notifications
							key={index}
							deleteNotification={deleteNotification}
							changeStatusNotification={changeStatusNotification}
							item={item}
						/>
					))
				) : (
					<MenuItem className='justify-between'>{t('notificationPopover.noNotificationsText')}</MenuItem>
				)}
			</div>
		</Popover>
	);
};
