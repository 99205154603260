import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { CONFIG } from '@/config';
import Loadable from '@components/loadable';
import { AuthRoutesLayout, AppLayout, PublicLayout } from '@components/layouts';

import {
	DEMO,
	REGISTER,
	PENDING_CONFIRM_EMAIL,
	COMPANY_DETAILS,
	FORGET_PASSWORD,
	RESET_PASSWORD,
	APP,
	FREIGHT,
	PROFILE,
	CONFIRM_EMAIL,
	SELECT_ROLE,
	OFFERS,
	REQUEST_DETAIL,
	INVITATION,
	MAP,
	FLEET,
	CHAT,
	ADMIN,
	DELIVERIES,
	SHARE,
	AVAILABLECAPACITY,
	CARRIERS,
	VESSELS,
	INVOICES,
	POSITIONS,
	HEATMAP,
	INSURANCE_ADMIN,
	COMPANY_PROFILE,
	SIGNUP,
	SIGNIN,
	ANONPOST,
	ANONTEST,
} from './path';

/*
import NewOfferSummary from '@/pages/offers/summary/NewOfferSummary';
import DeliveriesIndexBackOffice from '@/pages/backoffice/deliveries/DeliveriesIndex';
import StatsIndexBackOffice from '@/pages/backoffice/stats/StatsIndex';
import CapacityInquire from '@/pages/fleet/CapacityInquire';
import CapacityInquiry from '@/pages/fleet/CapacityInquiry';
*/
import { InitialLoader } from '@/components/loader/InitialLoader';
import HeatmapMap from '@/pages/heatmap/HeatmapMap';
import CompanyProfile from '@/pages/profile/CompanyProfile';
import { SignupLayout } from '@/components/layouts/PublicLayout';

const Inbox = Loadable(lazy(() => import('@/pages/chat/Inbox')));
const NewOfferSummary = Loadable(lazy(() => import('@/pages/offers/summary/NewOfferSummary')));
const DeliveriesIndexBackOffice = Loadable(
	lazy(() => import('@/pages/backoffice/deliveries/DeliveriesIndex')),
);
const OKRBackOffice = Loadable(lazy(() => import('@/pages/backoffice/stats/OKR')));
const StatsIndexBackOffice = Loadable(lazy(() => import('@/pages/backoffice/stats/StatsIndex')));
const CapacityInquiry = Loadable(lazy(() => import('@/pages/fleet/CapacityInquiry')));
const CapacityInquire = Loadable(lazy(() => import('@/pages/fleet/CapacityInquire')));
const Invoices = Loadable(lazy(() => import('@/pages/invoices/Invoices')));
const CirulationLists = Loadable(lazy(() => import('@/pages/backoffice/circulationLists')));
const Home = Loadable(lazy(() => import('@pages/Home')));
const Profile = Loadable(lazy(() => import('@pages/profile')));
const Maps = Loadable(lazy(() => import('@pages/map')));
const Offers = Loadable(lazy(() => import('@pages/offers')));
const CounterOffer = Loadable(lazy(() => import('@/pages/offers/counter-offers/CounterOffer')));
const Onboarding = Loadable(lazy(() => import('@pages/Onboarding')));
const CompanyDetails = Loadable(lazy(() => import('@pages/CompanyDetails')));
const EmailConfirmation = Loadable(lazy(() => import('@pages/EmailConfirmation')));
const NotFound = Loadable(lazy(() => import('@pages/NotFound')));
const FreightRequestCreate = Loadable(lazy(() => import('@/pages/freight/request')));
const FreightsBoard = Loadable(lazy(() => import('@/pages/freight/board')));
const FreightSummary = Loadable(lazy(() => import('@/pages/freight/summary')));
const ShipmentSummary = Loadable(lazy(() => import('@/pages/freight/summary/ShipmentSummary')));
const FreightBids = Loadable(lazy(() => import('@/pages/freight/bids')));
const RequestSummary = Loadable(lazy(() => import('@/pages/request/RequestSummary')));
const MakeOffer = Loadable(lazy(() => import('@/pages/offers/make-offer/MakeOffer')));
const OfferSummary = Loadable(lazy(() => import('@/pages/offers/summary/OfferSummary')));
const AvailableCapacity = Loadable(lazy(() => import('@/pages/fleet/AvailableCapacity')));
const Vessel = Loadable(lazy(() => import('@/pages/fleet/vessel')));
const VesselInfo = Loadable(lazy(() => import('@/pages/fleet/vessel-info')));
const SearchVesselByImo = Loadable(lazy(() => import('@/pages/fleet/search-vessel-by-imo')));
const AddNewVessel = Loadable(lazy(() => import('@/pages/fleet/add-new-vessel')));
const AddNewPositionList = Loadable(lazy(() => import('@/pages/positions/AddNewPositionList')));
const PositionsMap = Loadable(lazy(() => import('@/pages/positions/PositionsMap')));
const UsersBackoffice = Loadable(lazy(() => import('@pages/backoffice/users')));
const FreightRequestBackoffice = Loadable(lazy(() => import('@/pages/backoffice/freightRequests')));
const Carriers = Loadable(lazy(() => import('@/pages/carriers')));
const Vessels = Loadable(lazy(() => import('@/pages/vessels')));
const VesselsFleet = Loadable(lazy(() => import('@/pages/vessels/VesselsFleet')));
const FreightRequestDetailBackoffice = Loadable(
	lazy(() => import('@/pages/backoffice/freightRequests/detail')),
);
const OngoingFreights = Loadable(
	lazy(() => import('@/pages/Deliveries/ongoingFreights/OngoingFreights')),
);
const ViewOngoingFreight = Loadable(
	lazy(() => import('@/pages/Deliveries/viewOngoingFreight/ViewOngoingFreight')),
);
const ViewClickDetailBackoffice = Loadable(lazy(() => import('@/pages/backoffice/viewclick')));
const AdminCompanies = Loadable(lazy(() => import('@/pages/backoffice/companies')));

// Public pages
const SharedShipmentDetail = Loadable(lazy(() => import('@/pages/public/SharedShipment')));
const SharedVesselDetail = Loadable(lazy(() => import('@/pages/public/SharedVessel')));
const SignUp = Loadable(lazy(() => import('@/pages/public/SignUp')));
const SignIn = Loadable(lazy(() => import('@/pages/public/SignIn')));
const AnonPost = Loadable(lazy(() => import('@/pages/public/AnonPost')));
const AnonTest = Loadable(lazy(() => import('@/pages/public/AnonTest')));

// Demo frontpage
const DemoFrontpage = Loadable(lazy(() => import('@/pages/demo/DemoFrontpage')));

// Match making backoffice pages
const MatchMakingEditShipmentBackOffice = Loadable(
	lazy(() => import('@/pages/backoffice/ops/matchMaking/EditShipmentMatchMaking')),
);
const MatchMakingTodosBackOffice = Loadable(
	lazy(() => import('@/pages/backoffice/ops/matchMaking/MatchMakingTodos')),
);
const MatchMakingBackOffice = Loadable(
	lazy(() => import('@/pages/backoffice/ops/matchMaking/MatchMaking')),
);

const MatchMakingEmailApprovalsBackOffice = Loadable(
	lazy(() => import('@/pages/backoffice/ops/matchMaking/EmailApprovals')),
);

const PendingEmailConfirmation = Loadable(lazy(() => import('@/pages/PendingEmailConfirmation')));

// Admin for tryg
const InsuranceAdmin = Loadable(lazy(() => import('@/pages/insurance-admin/InsuranceAdmin')));

const authRoutes: RouteObject = {
	path: 'landing',
	element: <AuthRoutesLayout />,
	children: [
		{ index: true, element: <Home /> },
		{ path: INVITATION, element: <Onboarding /> },
		{ path: REGISTER, element: <Onboarding /> },
		{ path: SELECT_ROLE, element: <Onboarding /> },
		{ path: COMPANY_DETAILS, element: <CompanyDetails /> },
		{ path: CONFIRM_EMAIL, element: <EmailConfirmation /> },
		{ path: PENDING_CONFIRM_EMAIL, element: <PendingEmailConfirmation /> },
		{ path: FORGET_PASSWORD, element: <div>forgot page</div> },
		{ path: `${RESET_PASSWORD}/:UID/:token`, element: <div>reset page</div> },
	],
};

const adminRoutes: RouteObject = {
	path: ADMIN.INDEX,
	element: <AppLayout />,
	children: [
		{
			path: ADMIN.FREIGHT_REQUESTS.INDEX,
			children: [
				{
					index: true,
					element: <FreightRequestBackoffice />,
				},
				{
					path: ADMIN.FREIGHT_REQUESTS.DETAIL,
					element: <FreightRequestDetailBackoffice />,
				},
			],
		},
		{
			path: ADMIN.USERS.INDEX,
			element: <UsersBackoffice />,
		},
		{
			path: ADMIN.VIEWCLICKS.DETAIL,
			element: <ViewClickDetailBackoffice />,
		},
		{
			path: ADMIN.DELIVERIES.INDEX,
			element: <DeliveriesIndexBackOffice />,
		},
		{
			path: ADMIN.STATS.INDEX,
			element: <StatsIndexBackOffice />,
		},
		{
			path: ADMIN.OKR.INDEX,
			element: <OKRBackOffice />,
		},
		{ path: ADMIN.COMPANIES.INDEX, element: <AdminCompanies /> },
		{
			path: ADMIN.MATCH_MAKING.INDEX,
			children: [
				{
					path: ADMIN.MATCH_MAKING.MATCH_MAKING_TODO,
					element: <MatchMakingBackOffice />,
				},
				{
					path: ADMIN.MATCH_MAKING.SHIPMENT,
					element: <MatchMakingEditShipmentBackOffice />,
				},
				{
					path: ADMIN.MATCH_MAKING.TODOS,
					element: <MatchMakingTodosBackOffice />,
				},
				{
					path: ADMIN.MATCH_MAKING.EMAIL_APPROVALS,
					element: <MatchMakingEmailApprovalsBackOffice />,
				},
			],
		},
		{
			path: `${ADMIN.CIRCULATION.INDEX}/:tab`,
			element: <CirulationLists />,
		},
	],
};

const notFoundRoutes: RouteObject = {
	path: '*',
	element: <NotFound />,
};

const appRoutes: RouteObject = {
	path: APP,
	element: <AppLayout />,
	children: [
		{
			path: MAP,
			element: <Maps />,
		},
		{
			path: PROFILE,
			element: <Profile />,
		},
		{
			path: FREIGHT.INDEX,
			children: [
				{ path: FREIGHT.REQUEST, element: <FreightRequestCreate /> },
				{ path: FREIGHT.REQUEST_DRAFT, element: <FreightRequestCreate /> },
				{ path: FREIGHT.SUMMARY, element: <FreightSummary /> },
				{ path: FREIGHT.VIEW, element: <FreightSummary /> },
				{ path: FREIGHT.EDIT, element: <FreightRequestCreate /> },
				{ path: FREIGHT.BIDS, element: <FreightBids /> },
				{ path: FREIGHT.BUNDLE_SUMMARY, element: <ShipmentSummary /> },
				{ path: ':tab', element: <FreightsBoard /> },
				{ index: true, element: <FreightsBoard /> },
			],
		},
		{
			path: REQUEST_DETAIL.INDEX,
			children: [
				{ index: true, element: <RequestSummary /> },
				{ path: REQUEST_DETAIL.EDIT, element: <RequestSummary /> },
				{ path: REQUEST_DETAIL.MAKE_OFFER, element: <MakeOffer /> },
				{ path: REQUEST_DETAIL.OFFER_SUMMARY, element: <OfferSummary /> },
			],
		},
		{
			path: OFFERS.INDEX,
			children: [
				{ path: OFFERS.VIEW, element: <NewOfferSummary /> },
				{ path: OFFERS.EDIT, element: <MakeOffer /> },
				{ path: OFFERS.COUNTER_OFFER, element: <CounterOffer /> },
				{ path: ':tab', element: <Offers /> },
				{ index: true, element: <Offers /> },
			],
		},
		{
			path: FLEET.INDEX,
			children: [
				{ path: FLEET.VIEW, element: <VesselInfo /> },
				{ path: FLEET.EDIT, element: <Vessel /> },
				{ path: FLEET.ADD, element: <AddNewVessel /> },
				{ path: FLEET.AUTOFILL_SEARCH_IMO, element: <SearchVesselByImo /> },
				{ path: `${FLEET.AUTOFILL_SEARCH_IMO}/:imoNumber`, element: <SearchVesselByImo /> },
			],
		},
		{
			path: AVAILABLECAPACITY.INDEX,
			children: [
				{ index: true, element: <AvailableCapacity /> },
				{ path: AVAILABLECAPACITY.INQUIRE, element: <CapacityInquire /> },
				{ path: AVAILABLECAPACITY.INQUIRY, element: <CapacityInquiry /> },
			],
		},
		{
			path: CHAT.INDEX,
			children: [
				{ index: true, element: <Inbox /> },
				{ path: CHAT.CONVERSATION, element: <Inbox /> },
			],
		},
		{
			path: COMPANY_PROFILE.INDEX,
			children: [
				{ index: true, element: <CompanyProfile /> },
				{ path: COMPANY_PROFILE.TAB, element: <CompanyProfile /> },
				{ path: COMPANY_PROFILE.VIEW, element: <CompanyProfile /> },
				{ path: COMPANY_PROFILE.VIEW_TABS, element: <CompanyProfile /> },
				{ path: COMPANY_PROFILE.EDIT, element: <CompanyProfile /> },
				{ index: true, element: <CompanyProfile /> },
			],
		},
		{
			path: DELIVERIES.INDEX,
			children: [
				{ index: true, element: <OngoingFreights /> },
				{ path: DELIVERIES.TAB, element: <OngoingFreights /> },
				{ path: DELIVERIES.VIEW, element: <ViewOngoingFreight /> },
				{ path: DELIVERIES.VIEW_TABS, element: <ViewOngoingFreight /> },
			],
		},
		{
			path: CARRIERS.INDEX,
			element: <Carriers />,
		},
		{
			path: VESSELS.INDEX,
			children: [
				{ index: true, element: <Vessels /> },
				{ path: VESSELS.FLEET, element: <VesselsFleet /> },
			],
		},
		{
			path: INVOICES.INDEX,
			element: <Invoices />,
		},
		{
			path: POSITIONS.INDEX,
			children: [
				{ path: POSITIONS.ADD, element: <AddNewPositionList /> },
				{ path: POSITIONS.MAP, element: <PositionsMap /> },
			],
		},
		{
			path: HEATMAP.INDEX,
			children: [{ path: HEATMAP.MAP, element: <HeatmapMap /> }],
		},
		notFoundRoutes,
	],
};

const shareRoutes: RouteObject = {
	path: SHARE.INDEX,
	element: <PublicLayout />,
	children: [
		{
			path: SHARE.SHIPMENT.INDEX,
			children: [
				{
					path: SHARE.SHIPMENT.DETAIL,
					element: <SharedShipmentDetail />,
				},
			],
		},
		{
			path: SHARE.VESSEL.INDEX,
			children: [
				{
					path: SHARE.VESSEL.DETAIL,
					element: <SharedVesselDetail />,
				},
				{
					path: SHARE.VESSEL.DETAILSLUG,
					element: <SharedVesselDetail />,
				},
			],
		},
		{
			path: INSURANCE_ADMIN,
			element: <InsuranceAdmin />,
		},
	],
};

const signupRoutes: RouteObject = {
	path: SIGNUP,
	element: <SignupLayout />,
	children: [
		{
			index: true,
			element: <SignUp />,
		},
		{
			path: SIGNIN,
			element: <SignIn />,
		},
		{
			path: ANONPOST,
			element: <AnonPost />,
		},
		{
			path: ANONTEST,
			element: <AnonTest />,
		},
	],
};

const demoRoutes: RouteObject = {
	path: DEMO.INDEX,
	element: <PublicLayout />,
	children: [{ index: true, element: <DemoFrontpage /> }],
};

const routes: RouteObject[] = [
	{ path: '/', element: <InitialLoader /> },
	authRoutes,
	appRoutes,
	notFoundRoutes,
	adminRoutes,
	shareRoutes,
	signupRoutes,
	// publicVesselRoutes,
];

// Only add demo routes in demo environment
if (CONFIG.APP_ENV === 'demo') {
	routes.push(demoRoutes);
}

export default routes;
