/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
// @ts-nocheck

import { useState, useEffect, useCallback } from 'react';
import { Typography, TextField } from '@mui/material';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { BlueCheck } from '@/components/BlueCheck';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { APP, COMPANY_PROFILE } from '@/config/path';
import TabButton, { TabButtonsWrapper } from '@/components/TabButton';
import TabPanel from '../Deliveries/viewOngoingFreight/TabPanel';
import Card from '@/components/Card';
import { VesselDetails } from '../map/maps/VesselAccordion';
import Button from '@/components/Button';
import { CONFIG } from '@/config';
import { useAuth0 } from '@auth0/auth0-react';
import { z } from 'zod';
import {
	getCompanyById,
	getReviewsForCompany,
	getDocumentsForCompany,
	getFleetForCompany,
} from '@/apiV2/company';
import { getRelatedDeliveryDetails } from '@/apiV2/deliveryDetails';
import { UploadInput, ControlledField, ControlledSelect, useRHForm } from '@/components/form';
import {
	// Avatar,
	CircularProgress,
	Rating,
	Box,
	MenuItem,
	Select,
	FormControl,
} from '@mui/material';
import { useDeleteMedia, useMediaUpload } from '@/api/media';
import { toast } from 'react-toastify';
// import { useGetInvitedMembers } from '@/api/auth';
// import { stringAvatar } from './Team';
import UploadedFile from '@/components/UploadedFile';
import { CreditScore } from '@/components/CompanyInfo/CreditScore';
import vesselPlaceholderImage from '@/assets/images/vesselPlaceholderImage.png';

const TAB_INDEX = {
	FLEET: 'fleet',
	DOCUMENTS: 'documents',
	REVIEWS: 'reviews',
	TEAM: 'team',
};

export function starString(rating) {
	rating = Math.max(0, Math.min(5, rating));
	const roundedRating = Math.round(rating);

	const filledStar = '★';
	const emptyStar = '☆';
	const filledStars = filledStar.repeat(roundedRating);
	const emptyStars = emptyStar.repeat(5 - roundedRating);

	return filledStars + emptyStars;
}

const EditableCompanyDescription = ({ company, onSave, editable }) => {
	const { getAccessTokenSilently } = useAuth0();
	const [isEditing, setIsEditing] = useState(false);
	const [description, setDescription] = useState(company.description);

	const handleSave = async () => {
		try {
			const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
			const response = await fetch(`${CONFIG.API_V2}/company/set-description`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access_token}`,
				},
				body: JSON.stringify({ id: company._id, description }),
			});

			if (!response.ok) {
				throw new Error('Failed to update description');
			}
			setDescription(description);
			setIsEditing(false);
			toast.success('Company description saved!');
		} catch (error) {
			console.error('Error updating description:', error);
		}
	};

	const handleCancel = () => {
		setDescription(company.description);
		setIsEditing(false);
	};

	return (
		<div className='mt-4'>
			{isEditing ? (
				<div className='space-y-2'>
					<TextField
						multiline
						rows={4}
						fullWidth
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						variant='outlined'
						margin='normal'
						className='bg-white'
					/>
					<div className='flex flex-row gap-2'>
						<Button onClick={handleSave}>Save</Button>
						<Button variant='outlined' onClick={handleCancel}>
							Cancel
						</Button>
					</div>
				</div>
			) : (
				<div className='flex justify-between items-start'>
					<div className='text-md leading-1 text-keelspot-text-secondary'>{description}</div>
					{editable && (
						<Button variant='contained' color='primary' onClick={() => setIsEditing(true)}>
							Edit
						</Button>
					)}
				</div>
			)}
		</div>
	);
};

const CompanyProfile = () => {
	const { currentUser } = useGetCurrentUser();
	const { companyID, tab } = useParams();
	const { getAccessTokenSilently } = useAuth0();
	const [company, setCompany] = useState(null);
	const [reviews, setReviews] = useState([]);
	const [isVesselOwner, setIsVesselOwner] = useState(false);
	const [documents, setDocuments] = useState([]);
	const [vessels, setVessels] = useState([]);
	const [relatedDeliveries, setRelatedDeliveries] = useState([]);
	const navigate = useNavigate();

	const isOwnProfile = currentUser?.companyId === companyID;

	const navigateTab = useCallback((tab) => {
		navigate(
			generatePath(`/${APP}/${COMPANY_PROFILE.INDEX}/${COMPANY_PROFILE.VIEW_TABS}`, {
				companyID: company?._id,
				tab,
			}),
		);
	});

	useEffect(() => {
		const fetchCompanyData = async () => {
			if (company) {
				return;
			}
			try {
				const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
				const companyData = await getCompanyById({ id: companyID, access_token: access_token });
				setCompany(companyData);
				if (companyData?.primaryOperation?.slug === 'ship-operator') {
					setIsVesselOwner(true);
				}
				if (!tab && companyData?.primaryOperation?.slug !== 'ship-operator') {
					navigateTab('reviews');
				}
			} catch (error) {
				console.error('Error fetching company data:', error);
			}
		};

		fetchCompanyData();
	}, [companyID, company, getAccessTokenSilently, setIsVesselOwner, navigateTab, tab]);

	/*
	fleet
	*/

	const fetchFleet = useCallback(async () => {
		try {
			const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
			const fleet = await getFleetForCompany({ id: companyID, access_token });
			setVessels(fleet);
		} catch (error) {
			console.error('Error fetching fleet:', error);
		}
	}, [companyID, getAccessTokenSilently]);

	useEffect(() => {
		fetchFleet();
	}, [fetchFleet]);

	/*
	reviews
	*/

	const fetchReviews = useCallback(async () => {
		try {
			const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
			const reviews = await getReviewsForCompany({ id: companyID, access_token });
			setReviews(reviews);
		} catch (error) {
			console.error('Error fetching reviews:', error);
		}
	}, [companyID, getAccessTokenSilently]);

	useEffect(() => {
		fetchReviews();
	}, [fetchReviews]);

	const fetchRelatedDeliveries = useCallback(async () => {
		try {
			const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
			const dd = await getRelatedDeliveryDetails({ companyId: companyID, access_token });
			setRelatedDeliveries(dd);
		} catch (error) {
			console.error('Error fetching related deliveries:', error);
		}
	}, [companyID, getAccessTokenSilently]);

	useEffect(() => {
		fetchRelatedDeliveries();
	}, [fetchRelatedDeliveries]);

	/*
	// docs
	*/

	const fetchDocuments = useCallback(async () => {
		try {
			const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
			const apiDocs = await getDocumentsForCompany({ id: companyID, access_token });
			setDocuments(apiDocs);
		} catch (error) {
			console.error('Error fetching docs:', error);
		}
	}, [companyID, getAccessTokenSilently]);

	useEffect(() => {
		fetchDocuments();
	}, [fetchDocuments]);

	//
	// team
	//
	// const { data: teamMembers, isLoading: fetchingInvitedMembers } = useGetInvitedMembers();

	// ok lets go
	if (!currentUser || !company) {
		return (
			<div className='flex items-center justify-center h-full'>
				<CircularProgress color='info' size={20} />
			</div>
		);
	}

	return (
		<div className='flex flex-col gap-4 mb-4'>
			<div className='flex flex-row gap-4'>
				<div className='w-4/5'>
					<div className='flex flex-row gap-4 items-center'>
						<div className='w-1/12'>
							<img
								alt=' '
								className='w-full h-full aspect-square bg-white p-1 rounded-full shadow-md border-grey-400 border-1'
								src={company?.profilePhoto?.fullPath || vesselPlaceholderImage}
							/>
						</div>
						<div className='flex flex-col w-11/12'>
							<Typography variant='h4' color='text.primary'>
								<span>
									{company.name}
									{company.isBluecheck && <BlueCheck isBluecheck={true} size={28} />}
								</span>
							</Typography>
							<div>
								<span className='text-light text-xl leading-1 text-amber-400'>
									{starString(company?.reviewScore)}
								</span>
								<span className='text-light text-sm text-keelspot-text-secondary pl-6'>
									<i>
										{company?.reviewScore}/5, based on {company?.reviewCount} reviews
									</i>
								</span>
							</div>
							{company.creditDescription && company.creditGrade && company.creditPercent ? (
								<div className='pt-2 pl-1'>
									<CreditScore
										size={'sm'}
										creditScore={Number(company.creditPercent)}
										creditGrade={company.creditGrade}
										creditDescription={company.creditDescription}
									/>
								</div>
							) : null}
						</div>
					</div>
				</div>
				<div className='w-1/5'></div>
			</div>
			<div>
				<EditableCompanyDescription
					company={company}
					editable={isOwnProfile}
					onSave={(updatedCompany) => setCompany(updatedCompany)}
				/>
			</div>
			<div>
				<TabButtonsWrapper>
					{isVesselOwner && (
						<TabButton
							onClick={() => navigateTab(TAB_INDEX.FLEET)}
							selected={!tab || tab === TAB_INDEX.FLEET}
						>
							Fleet ({vessels?.length})
						</TabButton>
					)}
					<TabButton onClick={() => navigateTab(TAB_INDEX.REVIEWS)} selected={tab === TAB_INDEX.REVIEWS}>
						Reviews ({reviews?.length})
					</TabButton>
					<TabButton
						onClick={() => navigateTab(TAB_INDEX.DOCUMENTS)}
						selected={tab === TAB_INDEX.DOCUMENTS}
					>
						Documents ({documents?.length})
					</TabButton>
					{/* <TabButton onClick={() => navigateTab(TAB_INDEX.TEAM)} selected={tab === TAB_INDEX.TEAM}>
						Team ({teamMembers?.length})
					</TabButton> */}
				</TabButtonsWrapper>
			</div>
			{(tab === TAB_INDEX.FLEET || !tab) && (
				<div className='grid grid-cols-2 gap-2'>
					{Array.isArray(vessels) && vessels.length > 0 ? (
						vessels.map((vessel, index) => (
							<Card key={index}>
								<VesselDetails payload={vessel} />
							</Card>
						))
					) : (
						<TabPanel>
							<div className='text-keelspot-text-secondary'>No vessels available</div>
						</TabPanel>
					)}
				</div>
			)}
			{tab === TAB_INDEX.DOCUMENTS && (
				<div className='flex flex-col gap-2'>
					{isOwnProfile && (
						<TabPanel>
							<DocumentUploadForm vessels={vessels} callback={fetchDocuments} />
						</TabPanel>
					)}
					<TabPanel>
						<div className='flex flex-col gap-4'>
							{documents && documents.length > 0 ? (
								documents.map((document: any, idx) => (
									<div key={idx} className='flex flex-row gap-4'>
										<UploadedFile key={document.file._id} file={document.file} />
										<div className='flex flex-col gap-1'>
											<span className='text-keelspot-text-secondary text-md font-bold'>
												{document.description}
											</span>
											{document?.vessel?.name && (
												<span className='text-keelspot-text-secondary text-md'>🚢 {document.vessel.name}</span>
											)}
											<span className='text-keelspot-text-secondary text-xs'>
												{new Date(document.createdAt).toLocaleString()}
											</span>
										</div>
									</div>
								))
							) : (
								<p className='text-keelspot-text-secondary'>No documents uploaded yet!</p>
							)}
						</div>
					</TabPanel>
				</div>
			)}
			{tab === TAB_INDEX.REVIEWS && (
				<div className='flex flex-col gap-2'>
					{!isOwnProfile && (
						<TabPanel>
							<CreateReviewForm
								companyId={companyID}
								relatedDeliveries={relatedDeliveries}
								callback={fetchReviews}
							/>
						</TabPanel>
					)}
					<TabPanel>
						<div className='flex flex-col gap-4'>
							{reviews && reviews.length > 0 ? (
								reviews.map((review: any, idx) => (
									<div key={idx} className='flex flex-col gap-1'>
										<span className='text-amber-400'>{starString(review.stars)}</span>
										<span className='text-keelspot-text-secondary text-md'>{review.body}</span>
										<span className='text-keelspot-text-secondary text-xs'>
											{new Date(review.createdAt).toLocaleString()} {review.shipment && '✅'}
										</span>
									</div>
								))
							) : (
								<p className='text-keelspot-text-secondary'>No reviews yet!</p>
							)}
						</div>
					</TabPanel>
				</div>
			)}
			{/* {tab === TAB_INDEX.TEAM && (
				<TabPanel>
					{fetchingInvitedMembers ? (
						<CircularProgress />
					) : (
						teamMembers?.map((member: any) => {
							const fullName = member?.firstName ? `${member?.firstName} ${member?.lastName}` : '';

							return (
								<div className='flex' key={member?._id}>
									<div className='w-14 p-2'>
										<Avatar {...stringAvatar(`${member?.firstName} ${member?.lastName}`)} />
									</div>
									<div className='w-[1188px]'>
										<Typography variant='body1'>{fullName}</Typography>
									</div>
								</div>
							);
						})
					)}
				</TabPanel>
			)} */}
		</div>
	);
};

const CreateReviewForm = ({ companyId, relatedDeliveries, callback }) => {
	const [stars, setStars] = useState(0);
	const [body, setBody] = useState('');
	const [selectedShipment, setSelectedShipment] = useState('');
	const { getAccessTokenSilently } = useAuth0();

	const handleShipmentChange = (event) => {
		setSelectedShipment(event.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { access_token } = await getAccessTokenSilently({ detailedResponse: true });

			const response = await fetch(`${CONFIG.API_V2}/reviews/create`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access_token}`,
				},
				body: JSON.stringify({
					stars,
					body,
					shipmentId: selectedShipment,
					companyId,
				}),
			});

			if (!response.ok) {
				throw new Error('Failed to submit review');
			}

			toast.success('Review saved!');

			// Reset form
			setStars(0);
			setBody('');
			callback();
		} catch (error) {
			console.error('Error submitting review:', error);
		}
	};

	return (
		<Box component='form' onSubmit={handleSubmit}>
			<Typography variant='h6' gutterBottom>
				Leave a review
			</Typography>
			<Box mb={2}>
				<Rating
					name='rating'
					value={stars}
					onChange={(event, newValue) => {
						setStars(newValue);
					}}
				/>
			</Box>
			<FormControl fullWidth>
				<Select
					labelId='shipment-select-label'
					name='vessel'
					value={selectedShipment}
					onChange={handleShipmentChange}
					displayEmpty
					renderValue={selectedShipment !== '' ? undefined : () => 'Select a shipment (optional)'}
				>
					<MenuItem value=''>
						<em>Select a shipment...</em>
					</MenuItem>
					{relatedDeliveries?.map((delivery) => (
						<MenuItem key={delivery._id} value={delivery._id}>
							{delivery.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<TextField
				fullWidth
				multiline
				rows={4}
				variant='outlined'
				label='Review'
				value={body}
				onChange={(e) => setBody(e.target.value)}
				required
				margin='normal'
			/>
			<Button onclick variant='contained' color='primary' fullWidth>
				Submit Review
			</Button>
		</Box>
	);
};

const schema = z.object({
	description: z.string().min(1, 'Description is required'),
	vessel: z.string().optional(),
	attachments: z.array(z.any()).min(1, 'Please upload at least one file'),
});

const DocumentUploadForm = ({ vessels, callback }) => {
	const {
		Form,
		methods: {
			control,
			setValue,
			reset,
			watch,
			handleSubmit,
			formState: { errors },
		},
	} = useRHForm({
		initialValues: {
			file: [],
			attachments: [],
			description: '',
			vessel: '',
		},
		mode: 'onSubmit',
		schema,
	});
	const { getAccessTokenSilently } = useAuth0();

	const { mutateAsync: mutateMediaUploadAsync, isLoading: isUploadingMedia } = useMediaUpload();
	const { mutateAsync: mutateDeleteMediaAsync, isLoading: isDeletingMedia } = useDeleteMedia();
	const file = watch(`file`);
	const attachments = watch(`attachments`);
	const [isUploading, setIsUploading] = useState(false);

	useEffect(() => {
		if (!file) {
			return;
		}
		if (!file.length) {
			return;
		}

		mutateMediaUploadAsync({ file }).then((res) => {
			const newUploadedAttachments = res.multipleMediaUpload.data;
			const currentAttachments = attachments || [];
			setValue(`attachments`, [...currentAttachments, ...newUploadedAttachments]);
			setValue(`file`, []);
		});
	}, [file, attachments, mutateMediaUploadAsync, setValue]);

	const onSub = async (data) => {
		try {
			setIsUploading(true);
			const formData = {
				file: data.attachments.map((att) => att._id),
				description: data.description,
				vesselId: data.vessel,
			};

			const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
			const response = await fetch(`${CONFIG.API_V2}/documents/create`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access_token}`,
				},
				body: JSON.stringify(formData),
			});

			if (!response.ok) {
				toast.error('Failed to save document');
				throw new Error('Failed to save document');
			}

			toast.success('File uploaded successfully!');
			reset();
			callback();
		} catch (error) {
			toast.error(`Error: ${error.message}`);
		} finally {
			setIsUploading(false);
		}
	};

	const handleOnRemoveAttachment = (attachment) => {
		const updatedAttachments = attachments.filter((att) => att._id !== attachment._id);
		setAttachments(updatedAttachments);
		setValue('file', updatedAttachments);
	};

	const hasAddedAttachment = attachments?.length > 0;

	return (
		<div>
			<Form onSubmit={handleSubmit(onSub)}>
				<div className='flex flex-col gap-4'>
					<Typography variant='h6' gutterBottom>
						Upload documents
					</Typography>
					<UploadInput
						hideTextField={hasAddedAttachment}
						name='file'
						control={control}
						limit={1}
						multiple
						attachments={attachments}
						loading={isUploadingMedia || isDeletingMedia}
						onRemoveAttachment={handleOnRemoveAttachment}
						updateValue={setValue}
					/>

					<ControlledField
						rules={{ required: 'Required' }}
						control={control}
						name='description'
						Component={TextField}
						componentProps={{ label: 'Description', fullWidth: true }}
					/>

					<ControlledSelect control={control} name='vessel' label={'Select Vessel (optional)'}>
						{vessels?.map((vessel) => (
							<MenuItem key={vessel._id} value={vessel._id}>
								{vessel.name}
							</MenuItem>
						))}
					</ControlledSelect>

					<div className='flex flex-col gap-1'>
						<Button
							className='w-full'
							type='submit'
							disabled={isUploadingMedia}
							startIcon={isUploadingMedia ? <CircularProgress color='inherit' size={20} /> : null}
						>
							Attach file
						</Button>
					</div>
				</div>
			</Form>
		</div>
	);
};

export default CompanyProfile;
